import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import { Button } from "../components/ui";
import LogoXtremo from "../Images/xtremo-logo 1.png";
import PersonIcon from "@mui/icons-material/Person";
import "./Inicio.css";

const Inicio = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div style={{
      width: "100%",
      minHeight: "70vh",
      backgroundColor: "#1A1A1A"
    }}>
      
      <AppBar position="fixed" color="inherit" style={{ backgroundColor: "#0E0E0E" }}>
        <Toolbar style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }} className="container-toolbar">
          <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
            <img src={LogoXtremo} alt="logoGALLOMX" style={{ height: "25px", width: "200px" }} />
          </div>
          <div style={{ position: "absolute", right: 0 }}>
            {!isMobile ? (
              <div style={{ marginRight: "1em" }}>
                <Button style={{ width: "150px" }} onClick={() => navigate("/login")}>
                  INICIAR SESIÓN
                </Button>
              </div>
            ) : (
              <Typography variant="body1" color="white"
                onClick={() => navigate("/login")}
                style={{
                  padding: "6px 10px",
                  alignItems: "center",
                  display: "flex",
                }}>
                <PersonIcon style={{ color: "#38A169" }} className='icon-size' />
              </Typography>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <div className="container-bg-image">
        <video autoPlay muted loop playsInline preload="auto" style={{ width: "100%", height: isMobile ? "150px" : "380px", objectFit: "cover" }}>
          <source src="../../videos/video_pelea.webm" type="video/webm" />
          Tu navegador no soporta videos.
        </video>
        <Typography component="h1" variant="h5" color={'#FFFFFF'} align="center"
          sx={{ mt: window.innerWidth <= 868 ? 1.5 : 3, mb: 1.5 }} style={{ fontWeight: "bold", fontSize: window.innerWidth <= 868 ? "18px" : "22px", maxWidth: "400px", minWidth: "200px" }}>
          PELEAS DE GALLOS EN VIVO
        </Typography>
        <Button
          style={{ width: '150px' }}
          variant="outlined"
          onClick={(e) => navigate("/signup")}
        >
          ¡REGISTRARME!
        </Button>
        <Typography component="h1" variant="body1" color={"#ffa934"} align="left" sx={{ mb: 1, mt: 2 }} fontSize={"12px"}>
          REGISTRATE GRATIS EN SEGUNDOS
        </Typography>
        {/* <button
          onClick={(e) => navigate("/home-tutorials")}
          className="btn-login"
          style={{ backgroundColor: "#b35426", marginTop: "1em" }}
        >
          TUTORIALES
        </button> */}
      </div>

      <div className="container-steps">
        <div className="container-steps-parrafo" style={{ display: "flex" }}>
          <LooksOneIcon color="inherit" fontSize="inherit" style={{ fontSize: "33px", color: "#38A169", marginRight: "5px" }} />
          <Stack direction="column" justifyContent="center" alignItems="start" >
            <Typography component="h1" variant="h5" color={'#FFFFFF'} align="left" style={{ fontWeight: "bolder", fontSize: window.innerWidth <= 868 ? "18px" : "22px" }}>
              Acción
            </Typography>
            <Typography component="h1" variant="body1" color={"#FFFFFF"} align="left" fontSize={window.innerWidth <= 868 ? "12px" : "18px"}>
              ¡La arena está lista! ¿Qué esperas para registrarte?
            </Typography>
            <Typography component="h1" variant="body1" color={"#FFFFFF"} align="left" fontSize={window.innerWidth <= 868 ? "12px" : "18px"}>
              Regístrate gratis en segundos y disfruta la acción de las peleas de gallos en vivo.
            </Typography>
          </Stack>
        </div>
        <div className="container-steps-parrafo" style={{ display: "flex" }}>
          <LooksTwoIcon color="inherit" fontSize="inherit" style={{ fontSize: "33px", color: "#38A169", marginRight: "5px" }} />
          <Stack direction="column" justifyContent="center" alignItems="start" >
            <Typography component="h1" variant="h5" color={'#FFFFFF'} align="left" style={{ fontWeight: "bolder", fontSize: window.innerWidth <= 868 ? "18px" : "22px" }}>
              Adrenalina
            </Typography>
            <Typography component="h1" variant="body1" color={'#FFFFFF'} align="left" fontSize={window.innerWidth <= 868 ? "12px" : "18px"}>
              ¡Dos guerreros emplumados frente a frente! ¿Quién es tu favorito?
            </Typography>
            <Typography component="h1" variant="body1" color={'#FFFFFF'} align="left" fontSize={window.innerWidth <= 868 ? "12px" : "18px"}>
              Mete tu apuesta y vive la adrenalina de los golpes, velocidad y estrategia en cada pelea
            </Typography>
          </Stack>
        </div>

        <div className="container-steps-parrafo" style={{ display: "flex" }}>
          <Looks3Icon color="inherit" fontSize="inherit" style={{ fontSize: "33px", color: "#38A169", marginRight: "5px" }} />
          <Stack direction="column" justifyContent="center" alignItems="start" >
            <Typography component="h1" variant="h5" color={'#FFFFFF'} align="left" style={{ fontWeight: "bolder", fontSize: window.innerWidth <= 868 ? "18px" : "22px" }}>
              Honor
            </Typography>
            <Typography component="h1" variant="body1" color={"#FFFFFF"} align="left" fontSize={window.innerWidth <= 868 ? "12px" : "18px"}>
              ¡Sólo uno saldrá victorioso! ¿Quieres ganar?
            </Typography>
            <Typography component="h1" variant="body1" color={"#FFFFFF"} align="left" fontSize={window.innerWidth <= 868 ? "12px" : "18px"}>
              Aquí sólo los mejores sobreviven. Esto no es sólo una pelea. ¡Es un duelo de hono!
            </Typography>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Inicio;